import React from "react"

import LessonSender from "../components/LessonSender"

const LessonSenderPage = () => {
  if (typeof window === "undefined") return null

  return <LessonSender />
}

export default LessonSenderPage
