import React, { useEffect, useState, useRef } from "react"
// import PropTypes from 'prop-types'
import SEND_LESSON_TO_GROUPS from "./sendLessonToGroups.graphql"
import GET_TUTORING_GROUPS from "./getTutoringGroups.graphql"
import { useApolloClient } from "@apollo/client"
import Loader from "../UI/Loader"
import { Box, TextInput, Form, Grid, Heading } from "grommet"
import { colors } from "../../theme"
import styled, { css } from "styled-components"

const Wrapper = styled.div`
  ul {
    margin-bottom: 0;
  }
`
const Groups = styled.div`
  > :last-child {
    display: flex;
    > div {
      margin-right: 15px;
    }
    > :last-child {
      margin-right: 0;
    }
  }
`

const AgeRange = styled.div`
  display: flex;
  > div {
    display: flex;
    align-items: center;
    label {
      font-size: 19px;
      margin-right: 8px;
    }
  }
  > :first-child {
    margin-right: 15px;
  }
  margin-bottom: 20px;
`

const Group = styled(Box)`
  flex: 1;
  color: ${colors.indigo};
  min-width: 120px;
  cursor: pointer;
  user-select: none;
  ${props =>
    props.selected &&
    css`
      background-color: ${colors.indigo};
      color: #fff;
    `}
`
const TARGET_GROUPS = ["5978f1fd9a1d7200041e9872"]

const LessonSender = ({ _id }) => {
  const [loading, setLoading] = useState(true)
  const [groups, setGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [groupsSentTo, setGroupsSentTo] = useState([])
  const [ageRange, setAgeRange] = useState({ from: 0, to: 7 })
  const [sent, setSent] = useState(false)
  const client = useApolloClient()
  const url = useRef()
  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const init = async () => {
    const { data } = await client.query({
      query: GET_TUTORING_GROUPS,
    })
    setGroups(data.tutoringGroups || [])
    setLoading(false)
  }

  const toggleSelectGroup = group => {
    const isSelected =
      selectedGroups.filter(g => g._id === group._id).length > 0
    if (isSelected) {
      setSelectedGroups(selectedGroups.filter(g => g._id !== group._id))
    } else {
      setSelectedGroups([...selectedGroups, group])
    }
  }

  const onSubmit = async e => {
    const lessonId = url.current.value.split("/")[
      url.current.value.split("/").length - 1
    ]
    if (lessonId.length !== 24) {
      throw new Error("Invalid Id")
    }
    const { data } = await client.mutate({
      mutation: SEND_LESSON_TO_GROUPS,
      variables: {
        lessonId,
        ageRange,
        groupIds: selectedGroups.map(g => g._id),
      },
    })
    if (data.sendLessonToGroups.length === TARGET_GROUPS.length) {
      url.current.value = ""
    }
    setSent(true)
    setGroupsSentTo(data.sendLessonToGroups)
  }

  if (loading) return <Loader />
  return (
    <Wrapper>
      <Grid gap="small">
        {groups.length > 0 && (
          <Groups>
            <Heading level="4" color={colors.indigo}>Age range</Heading>
            <AgeRange>
              <div>
                { /* eslint-disable-next-line */ }
                <label>From</label>
                <TextInput type='number' value={ageRange.from} onChange={e => setAgeRange({ ...ageRange, from: parseInt(e.target.value) })} />
              </div>
              <div>
                { /* eslint-disable-next-line */ }
                <label>To</label>
                <TextInput type='number' value={ageRange.to} onChange={e => setAgeRange({ ...ageRange, to: parseInt(e.target.value) })} />
              </div>
            </AgeRange>
            <Heading level="4" color={colors.indigo}>Select groups</Heading>
            <div>
            {groups.map(group => (
              <Group
                selected={
                  selectedGroups.filter(g => g._id === group._id).length > 0
                }
                key={group._id}
                background="#fff"
                pad="small"
                elevation="medium"
                round="small"
                onClick={() => toggleSelectGroup(group)}
              >
                <div>{group.name}</div>
              </Group>
            ))}
            </div>
          </Groups>
        )}
        {sent && (
          <Box background="#fff" pad="medium" elevation="medium" round="small">
            <Heading level="4" color={colors.indigo}>
              Sent!
            </Heading>
          </Box>
        )}
        {groupsSentTo.length > 0 && (
          <Box background="#fff" pad="medium" elevation="medium" round="small">
            <Heading level="4" color={colors.indigo}>
              Sent to
            </Heading>
            <ul>
              {groupsSentTo.map(group => (
                <li key={group._id}>{group.name}</li>
              ))}
            </ul>
          </Box>
        )}
        { selectedGroups.length > 0 && <Box background="#fff" pad="medium" elevation="medium" round="small">
          <Heading level="4" color={colors.indigo}>
            Lesson URL
          </Heading>
          <Form onSubmit={onSubmit}>
            <TextInput ref={url} placeholder="Paste lesson link here" />
          </Form>
        </Box> }
      </Grid>
    </Wrapper>
  )
}

LessonSender.propTypes = {}

LessonSender.defaultProps = {}

export default LessonSender
